import React from 'react'

export default () => {
  return (
    <ul style={{ padding: '20px' }}>
      <li>
        Latanoprost Prescribing Information,{' '}
        <a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2014/020597s045s048lbl.pdf">click here</a>
      </li>
      <li>
        Minoxidil Prescribing Information, <a href="https://www.ncbi.nlm.nih.gov/books/NBK482378/">click here</a>
      </li>
      <li>
        Tretinoin Prescribing Information,{' '}
        <a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2014/020475s021lbl.pdf">click here</a>
      </li>
    </ul>
  )
}
